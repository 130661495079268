<template>
  <b-collapse :id="'topIconsCollapse' + showType + icon.iconNumber" :class="{ collapsed: visible }"
    :aria-expanded="visible" class="top-icons--drop cart" ref="cart" v-model="visible" @shown="recalculateHeight">

    <component :is="`style`" v-html="dropDownStyle"> </component>
    <!-- <span v-if="icon.dropHeading" class="drop-heading"
      v-html="icon.dropHeading.replace(/&gt;/g, '>').replace(/&lt;/g, '<')"></span> -->

    <ul class="drop-items">
      <li v-for="dropItem of icon.dropItems" :key="dropItem.dropItemId">
        <div class="d-flex align-items-start">
          <div class="drop-item--img">
            <b-img :src="dropItem.product.image.x_small" :alt="dropItem.product.name" width="150" height="39">
            </b-img>
          </div>
          <div class="drop-item--info">
            <span class="product-name d-block">{{ dropItem.product.name }}</span>
            <span class="product-number ">Produktnummer: <span>{{ dropItem.product.sku }}</span> </span>
            <span class="product-inhalt">Inhalt: <span> {{ dropItem.product.inhalt }} </span> </span>
            <div v-if="typeof dropItem.configurable_options != 'undefined'">
              <span v-for="(option, index) of dropItem.configurable_options" :key="index"
                class="product-options d-block">
                <span class="product-options-title">{{ option.option_label }}:&nbsp;</span>
                <span class="product-options-value">{{ option.value_label }}</span>
              </span>
            </div>
            <div v-if="typeof dropItem.bundle_options != 'undefined'">
              <div>
                <span v-for="(option, index) of dropItem.bundle_options" :key="index" class="product-options d-block">
                  <span>{{ option.label }}: </span>

                  <span v-if="typeof option.values[0].configurable_options != 'undefined'">
                    <span v-for="(opt, idx) of option.values[0]
                      .configurable_options" :key="idx">
                      <span class="product-options-title">{{ opt.option_label }}:&nbsp;</span>
                      <span class="product-options-value">{{ opt.value_label }}</span>
                    </span>
                  </span>
                </span>
              </div>
              <span v-for="(option, index) of dropItem.configurable_options" :key="index"
                class="product-options d-block">
                <span class="product-options-title">{{ option.option_label }}:&nbsp;</span>
                <span class="product-options-value">{{ option.value_label }}</span>
              </span>
            </div>

            <div class="quantity-price-box">
              <div class="col-5 price-box price">
                {{ formatCurrency(dropItem.prices.row_total_including_tax.value) }}
              </div>
              <div class="col-7 quantity-box">
                <div class="quantity-input d-flex">
                  <b-link href="#" class="step-down-btn" @click="quantityDown(dropItem.id, dropItem.quantity)"></b-link>
                  <input type="number" min="1" :value="dropItem.quantity" name="quantity" ref="inputQuantity"
                    disabled />
                  <b-link href="#" class="step-up-btn" @click="quantityUp(dropItem.id, dropItem.quantity)"></b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="lnr lnr-cross" @click.prevent="removeItem(dropItem.id)"></a>
      </li>
    </ul>
    <div class="drop-bottom ">

      <div class="d-flex justify-content-between">
        <h6>{{ $t("subtotal") }}:</h6>
        <span class="minicart-value"> {{ formatCurrency(icon.cartFooter.totalPrice) }}</span>
      </div>

      <div v-if="getSelectedShippingMethod && getSelectedShippingMethod.amount_including_tax" class="d-flex">
        <h6>{{ $t("shipping_cost") }}: </h6>
        <span class="minicart-value"> {{ formatCurrency(getSelectedShippingMethod.amount_including_tax.value) }}</span>
      </div>

      <div v-if="cartTotalDiscounts != null">
      <ul>
                  <li class="d-flex justify-content-between" v-for="(discount, index) in cartTotalDiscounts"
                    :key="index">
                    <h6>{{ discount.label }}: </h6>
                    <span class="minicart-value">- {{ formatCurrency(discount.amount.value) }}</span>
                  </li>
                </ul>
      </div>

      <div class="d-flex justify-content-between cart-total">
        <h6 class="total">{{ $t("total_cart_title") }}:</h6>
        <span class="minicart-value"> {{ formatCurrency(cartTotalPrice) }}</span>
      </div>

      <!-- <span class="name">{{ $t("shipping_cost") }} {{ defaultShipping.carrier_title }}</span> -->
      <!-- <span>{{ icon }  }</span> -->
      <!-- <span v-if="typeof defaultShipping.amount != 'undefined'" class="cost ml-10">
        {{ formatCurrency(defaultShipping.amount.value) }}</span> -->
    </div>
    <div class="d-flex drop-bottom-footer justify-content-between align-items-center" v-if="icon.cartFooter">
      <b-link to="/checkout/cart">{{ $t("goto_cart") }}</b-link>
      <b-button variant="warning" @click="$router.push('/checkout')" v-bind:disabled="btnDisable">
        {{ icon.cartFooter.btnText }}
      </b-button>
    </div>
    <div class="language-block" v-if="icon.language">
      <ul class="d-flex list-unstyled">
        <li v-for="lang of icon.language" :key="lang.alt">
          <b-link :href="lang.href">
            <img :src="lang.img" :alt="lang.alt" />
          </b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
// import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import Rating from "@/esf_kerkrade_vitanatura/core/components/Rating";
import { mapActions } from "vuex";
import store from "@/store";

export default {
  name: "HeaderIconsDropdownCart",
  components: {
    ClientOnly,
    Rating
  },
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mixins: [Cart],
  computed: {
    btnDisable() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      return shmed;
    },
    cartTotalDiscounts: () => {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        const prices = store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          return prices.discounts;
        }
        return [];
      } else {
        return [];
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
      dropDownStyle: "max-height: calc(100vh - 0px) !important",
      divHeight: 0,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    formatCurrency(value) {
      return this.$helpers.formatCurrency(value);
    },
    closeDropdownMenu(e) {
      if (
        this.$el.parentElement &&
        !this.$el.parentElement.contains(e.target)
      ) {
        this.visible = false;
        if (!isServer) {
          document.body.classList.remove("noScroll");
        }
      } else {
        this.visible = true;
        if (!isServer) {
          document.body.classList.add("noScroll");
        }
      }
    },
    closeOnClick() {
      this.visible = false;
      if (!isServer) {
        document.body.classList.remove("noScroll");
      }
    },
    calculateDivHeight() {
      if (!isServer) {
        const filterSectionDOM = this.$refs.cart.$el;
        var height = filterSectionDOM
          ? filterSectionDOM.getBoundingClientRect().top
          : 0;
        return height;
      } else {
        return 0;
      }
    },
    recalculateHeight() {
      if (!isServer) {
        const filterSectionDOM = this.$refs.cart.$el;
        var height = filterSectionDOM
          ? filterSectionDOM.getBoundingClientRect().top
          : 0;
        this.dropDownStyle =
          "#topIconsCollapseM3 { max-height: calc(100vh - " +
          height +
          "px) !important}";
      }
    },
  },
};
</script>
